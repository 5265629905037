import React from "react";
import SkillPil from "./SkillPil";
import { CgWebsite } from "react-icons/cg";
import { FaLink } from "react-icons/fa";
import { RiDiscordLine } from "react-icons/ri";
import { ImAndroid } from "react-icons/im";


// w-[416px]
const CategoryIcon = {
  "website": CgWebsite,
  "discord": RiDiscordLine,
  "android": ImAndroid,
}


const ProjectCard = ({ props }) => {
  const {name, category, scope, description, imageFile, imageLink, skills, links} = props

  return (
    <div name="projectcard" className="flex flex-col max-w-[620px] border border-black rounded-lg">

      {/* ********************** TITLE ******************** */}
      <div className="width-full flex border border-black rounded-t bg-black text-white pl-4 h-16 items-center">
        {React.createElement(CategoryIcon[category])}
        {/* <CategoryIcon size="20px" /> */}
        <p className="ml-3">{name}</p>
      </div>

      <div className="px-4 py-2">
        {/* ********************** IMAGE ******************** */}
        <a href={imageLink} target="_blank" rel="noreferrer" className="relative overflow-hidden bg-no-repeat bg-cover my-4">
          <div className="flex justify-center">
            <img src={require(`../assets/${imageFile}`)} alt="thumbnail" className=" border border-zinc-400" />
          </div>
          <div className="absolute top-0 right-0 bottom-0 left-0 w-full h-full overflow-hidden bg-fixed opacity-0 hover:opacity-40 transition duration-300 ease-in-out bg-steel-blue">
          </div>
        </a>

        {/* **************** SCOPE/DESCR *************** */}
        <div className="mt-3">
          <p className="font-bold">Scope</p>
          {scope.map((item) => {
            return (
              <li key={item} className="ml-2">{item}</li>
            )}
          )}
          <p className="mt-2 font-bold">Description</p>
          <p className="">{description}</p>
        </div>

        {/* ********************* SKILLS ******************** */}
        <div className="">
          <p className="mt-4 font-bold">Skills</p>
          <div className="flex flex-wrap mt-2 gap-4">
            {skills.map((skill) => {
              return (
              <SkillPil key={skill} skill={skill} />
              )}
            )}
          </div>
        </div>

        {/* ********************** LINKS ******************** */}
        <FaLink size="18px" className="mt-4 mb-2" />
        {links.map((link) => {
          return (
            <a href={link[1]} key={link[0]} target="_blank" rel="noreferrer" className="mr-4 text-blue-700">{link[0]}</a>
          )
        })}
      </div>
    </div>
  )
}

export default ProjectCard;