import React from "react";
import projects from "./projectsList";
import ProjectCard from "../components/ProjectCard";

const Projects = () => {

  return (
    <div name="projects" className="bg-white">
      <div className="flex flex-col pt-32 px-8">
        <div className="text-2xl 2xl:text-3xl text-stone-400 font-nunito-sans tracking-[0.5em]">
          <p className="mb-4">PROJECTS</p>
        </div>
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-16 p-6">
          {projects.map((project) => {
            return (
              <ProjectCard key={project.id} props={project} />
            )}
          )}
        </div>
      </div>
    </div>
  )
}

export default Projects;