const projects = [
  {
    id: 1,
    name: "Personal Portfolio Website",
    category: "website",
    scope: [
      "Responsive React single-page application (solo contributor)",
      "Live deployment using Firebase hosting"
    ],
    description: "A personal website showcasing projects and emphasizing clean front-end design elements developed from \"scratch\" (i.e., not copied from a tutorial or existing code base).",
    imageFile: "thumbnail-portfolio.png",
    imageLink: "https://johnjliu-site.web.app",
    skills: ["React", "Tailwind CSS", "Firebase"],
    links: [
      ["Website", "https://johnjliu.com"],
    ]
  },
  {
    id: 2,
    name: "Discord FAQ AI Bot",
    category: "discord",
    scope: [
      "Hackathon (solo) submission, Shellhacks 2022 (in-person), Sep 2022",
      "Winner, 3rd place, The Assurant Way Challenge",
    ],
    description: "Before ChatGPT was a thing, Discord bot that uses scraped data and an ML model trained using Google Vertex AI and deployed on GCP to predict whether a new posted question has been asked in the past.",
    imageFile: "thumbnail-shellhacks.png",
    imageLink: "https://devpost.com/software/tbd-phvuid",
    skills: ["Python", "Machine Learning", "GCP"],
    links: [
      ["GitHub", "https://github.com/liujohnj/discord-faq-bot"],
      ["DevPost", "https://devpost.com/software/tbd-phvuid"],
    ]
  },
  {
    id: 3,
    name: "White Hat Hacker RPG Challenge",
    category: "android",
    scope: [
      "Hackathon (solo) submission, Secureworks Cybersecurity Literacy Challenge, May 2022",
    ],
    description: "2D RPG Android game that educates players about cybersecurity vulnerabilities like weak and stolen passwords, password cracking, poor logout habits, and wifi sniffing.",
    imageFile: "thumbnail-secureworks.png",
    imageLink: "https://devpost.com/software/white-hat-hacker-rpg-challenge",
    skills: ["Flutter", "Flame game engine", "Dart", "Android"],
    links: [
      ["GitHub", "https://github.com/liujohnj/secureworks-1"],
      ["DevPost", "https://devpost.com/software/white-hat-hacker-rpg-challenge"],
      ["APK", "https://drive.google.com/drive/folders/1RlEdhCBFeUVsc7aFoT_Af23N2zAdH9mS?usp=sharing"],
      ["Demo", "https://liujohnj.github.io/secureworks-1/"],
    ]
  },
  {
    id: 4,
    name: "Smart FARBAR",
    category: "website",
    scope: [
      "Hackathon (solo) submission, The 6-week Smart Contract Hackathon, Nov 2021",
      "Winner, Runner Up",
    ],
    description: "Full-stack, smart contract web application of workflow for (Florida) FAR/BAR real estate sale and purchase transaction featuring immutability of blockchain ledger and provacy protections of Daml framework.",
    imageFile: "thumbnail-daml.png",
    imageLink: "https://devpost.com/software/smart-far-bar",
    skills: ["Blockchain", "REST", "React", "JavaScript"],
    links: [
      ["GitHub", "https://github.com/liujohnj/smart-far-bar"],
      ["DevPost", "https://devpost.com/software/smart-far-bar"],
    ]
  },
  {
    id: 5,
    name: "TeacherStats",
    category: "website",
    scope: [
      "Hackathon (solo) submission, CovLab Global II: EduTech, Oct 2021",
      "Winner, 2nd place",
    ],
    description: "Full-stack web application that leverages Zoom APIs in a teacher dashboard with customizable benchmarks to measure virtual classroom performance and effectiveness.",
    imageFile: "thumbnail-covlab2.png",
    imageLink: "https://devpost.com/software/tbd-vyuxpk",
    skills: ["zoom", "REST", "MongoDB", "Express", "Node.js", "React"],
    links: [
      ["GitHub", "https://github.com/liujohnj/teacher-stats"],
      ["DevPost", "https://devpost.com/software/tbd-vyuxpk"],
    ]
  },
]

export default projects;