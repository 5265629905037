import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu,  AiOutlineFilePdf, AiOutlineDownload } from "react-icons/ai";
import Logo from "../assets/JL_logo_name_gray1.png";
import { Link } from "react-scroll";

const resumeFilename = "Resume_John_Liu_231111.pdf"
const resumeFileLocation = "/" + resumeFilename


const Navbar = () => {
  const [nav, setNav] = useState(false);
  const textColor = "#57534E";

  const links = [
    {
      id: '1',
      name: "home"
    },
    {
      id: '2',
      name: "projects"
    },
    {
      id: '3',
      name: "about"
    },
    {
      id: '4',
      name: "connect"
    },
  ];

  // const listLinks = navLinks.map((navLink) =>
  //   <li className="ml-10 text-sm hover:border-b">{navLink.name}</li>
  // )

  const handleNav = () => {
    setNav(!nav);
  };

  // const onButtonClick = () => {
  //   fetch("../assets/Resume_John_Liu_221207.pdf").then(response => {
  //     response.blob().then(blob => {
  //       const fileURL = window.URL.createObjectURL(blob);
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "../assets/Resume_John_Liu_221207.pdf";
  //       alink.click();
  //     });
  //   });
  // };

  return (
    <div className="fixed w-full h-20 bg-white bg-opacity-95 border-b border-gray-100 z-40">
      <div className="flex justify-between items-center w-full h-full px-2 2xl:px-16">
        <div>
          <a href="#home">
            <img src={Logo} alt="Logo" style={{width: '175px'}} />
          </a>
        </div>


        {/* <div>
          <button className="flex items-center gap-1 text-xl text-steel-blue" onClick={onButtonClick}>
            <AiOutlineFilePdf size={20} />
            <span className="mx-1 font-nunito-sans text-lg text-steel-blue">Résumé</span>
            <AiOutlineDownload size={20} />
          </button>
        </div> */}

        <div>
          <a href= {resumeFileLocation} download={resumeFilename}>
            <button className="flex items-center gap-1 text-xl text-steel-blue">
              <AiOutlineFilePdf size={20} />
              <span className="mx-1 font-nunito-sans text-lg text-steel-blue">Résumé</span>
              <AiOutlineDownload size={20} />
            </button>
          </a>
        </div>

        {/* web navbar links */}
        <div>
          <ul style={{ color: `${textColor}` }} className="hidden md:flex">
            {links.map((link) => <li className="mx-3 font-nunito-sans text-lg cursor-pointer hover:scale-110 hover:text-steel-blue capitalize" key={link.id}><Link to={link.name} smooth={true} duration={500}>{link.name}</Link></li>)}
          </ul>
          <div onClick={handleNav} className="md:hidden">
            <AiOutlineMenu size={25} />
          </div>
        </div>
      </div>

      {/* mobile navbar links */}
      <div
        className={
          nav ? "md:hidden fixed left-0 top-0 w-full h-screen bg-black/70" : ""
        }
      >
        <div
          className={
            nav
              ? "fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen bg-[#ecf0f3] p-10 ease-in duration-500"
              : "fixed left-[-100%] top-0 p-10 ease-in duration-500"
          }
        >
          <div>
            <div className="flex w-full items-center justify-between">
              <img src={Logo} alt="logo" width="175" />
              <div
                onClick={handleNav}
                className="rounded-full shadow-lg shadow-gray-400 p-3 hover:cursor-pointer hover:scale-125"
              >
                <AiOutlineClose className="" />
              </div>
            </div>
          </div>
          <div>
            <ul>
              {links.map((link) => <li className="py-6 text-md text-zinc-800 cursor-pointer capitalize hover:scale-110 hover:text-steel-blue w-min" key={link.id} href={link.ref}><Link onClick={handleNav} to={link.name} smooth={true} duration={500}>{link.name}</Link></li>)}
            </ul>
          </div>
          <div className="mt-20">
            <a href={resumeFileLocation} download={resumeFilename}>
              <button className="flex items-center gap-1 text-xl text-steel-blue">
                <AiOutlineFilePdf size={20} />
              <span className="mx-1 font-nunito-sans text-lg text-steel-blue">Résumé</span>
              <AiOutlineDownload size={20} />
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
