import React from "react";
import { SiExpress, SiGooglecloud, SiJavascript, SiMongodb, SiNodedotjs, SiPython, SiReact, SiTailwindcss, SiFirebase, SiFlutter, SiDart } from "react-icons/si";
import { TbApi } from "react-icons/tb";
import { GiBrain } from "react-icons/gi";
import { IoMdFlame } from "react-icons/io";
import { ImAndroid } from "react-icons/im";


const SkillPil = ({ skill }) => {

  const pil = {
    "React": {
      "icon": SiReact,
      "bgColor": "bg-react-blue",
      "bgOpacity": "bg-opacity-30",
      "textColor": "text-blue-800",
    },
    "Python": {
      "icon": SiPython,
      "bgColor": "bg-python-yellow",
      "bgOpacity": "bg-opacity-70",
      "textColor": "text-python-blue",
    },
    "JavaScript": {
      "icon": SiJavascript,
      "bgColor": "bg-javascript-yellow",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-javascript-gray",
    },
    "Express": {
      "icon": SiExpress,
      "bgColor": "bg-nodejs-gray",
      "bgOpacity": "bg-opacity-20",
      "textColor": "text-nodejs-gray",
    },
    "MongoDB": {
      "icon": SiMongodb,
      "bgColor": "bg-mongodb-green",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-mongodb-gray",
    },
    "Node.js" : {
      "icon": SiNodedotjs,
      "bgColor": "bg-nodejs-green",
      "bgOpacity": "bg-opacity-30",
      "textColor": "text-nodejs-gray",
    },
    "REST": {
      "icon": TbApi,
      "bgColor": "bg-nodejs-gray",
      "bgOpacity": "bg-opacity-20",
      "textColor": "text-nodejs-gray",
    },
    "GCP": {
      "icon": SiGooglecloud,
      "bgColor": "bg-google-red",
      "bgOpacity": "bg-opacity-20",
      "textColor": "text-google-blue",
    },
    "zoom": {
      "icon": TbApi,
      "bgColor": "bg-zoom-blue",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-white",
    },
    "Machine Learning": {
      "icon": GiBrain,
      "bgColor": "bg-gray-800",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-white",
    },
    "Tailwind CSS": {
      "icon": SiTailwindcss,
      "bgColor": "bg-tailwind-gray",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-tailwind-blue",
    },
    "Firebase": {
      "icon": SiFirebase,
      "bgColor": "bg-tailwind-yellow",
      "bgOpacity": "bg-opacity-40",
      "textColor": "text-tailwind-orange",
    },
    "Blockchain": {
      "icon": GiBrain,
      "bgColor": "bg-gray-800",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-white",
    },
     "Flutter": {
      "icon": SiFlutter,
      "bgColor": "bg-flutter-blue",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-white",
    },
     "Flame game engine": {
      "icon": IoMdFlame,
      "bgColor": "bg-flame-orange",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-yellow-200",
    },
     "Dart": {
      "icon": SiDart,
      "bgColor": "bg-dart-blue",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-blue-200",
    },
     "Android": {
      "icon": ImAndroid,
      "bgColor": "bg-android-green",
      "bgOpacity": "bg-opacity-100",
      "textColor": "text-white",
    },
  }

  return (
    <button className={`flex items-center gap-2 rounded-full px-3 py-1 ${pil[skill]["bgColor"]} ${pil[skill]["bgOpacity"]} ${pil[skill]["textColor"]}`}>
      {React.createElement(pil[skill]["icon"])}
      <span>{`${skill}`}</span>
    </button>

  )
}

export default SkillPil;