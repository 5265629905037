import React, { useState, setState } from "react";
import { AiOutlineLinkedin, AiFillLinkedin, AiOutlineGithub, AiFillGithub, AiOutlineMail, AiFillMail, AiOutlineLink  } from "react-icons/ai";
import { VscGithub, VscGithubInverted } from "react-icons/vsc";
import {RiDiscordLine, RiDiscordFill} from "react-icons/ri";

const Connect = () => {

  const [isHoverLinkedin, setIsHoverLinkedin] = useState(false);
  const [isHoverGithub, setIsHoverGithub] = useState(false);
  const [isHoverEmail, setIsHoverEmail] = useState(false);
   const [isHoverDiscord, setIsHoverDiscord] = useState(false);

  const handleMouseEnterLinkedin = () => {
    setIsHoverLinkedin(true);
  }
  const handleMouseLeaveLinkedin = () => {
    setIsHoverLinkedin(false);
  }
  const handleMouseEnterGithub = () => {
    setIsHoverGithub(true);
  }
  const handleMouseLeaveGithub = () => {
    setIsHoverGithub(false);
  }
  const handleMouseEnterEmail = () => {
    setIsHoverEmail(true);
  }
  const handleMouseLeaveEmail = () => {
    setIsHoverEmail(false);
  }
  const handleMouseEnterDiscord = () => {
    setIsHoverDiscord(true);
  }
  const handleMouseLeaveDiscord = () => {
    setIsHoverDiscord(false);
  }

  return (
    <div name="connect" className="flex flex-col pt-32 px-8 bg-white">
      <div className="text-2xl 2xl:text-3xl text-stone-400 font-nunito-sans tracking-[0.5em]">
        <p className="mb-4">LET'S CONNECT</p>
      </div>

      <div className="flex flex-col mt-8">
        <p className="font-nunito-sans text-center text-xl text-zinc-700">Contact me anytime, even if just to chat!</p>
        <div className="flex justify-evenly my-8">

          <a href="https://linkedin.com/in/johnjliu" target="_blank" onMouseEnter={handleMouseEnterLinkedin} onMouseLeave={handleMouseLeaveLinkedin} >
            {!isHoverLinkedin && <AiOutlineLinkedin size={60} />}
            {isHoverLinkedin && <AiFillLinkedin size={60} />}
          </a>

          <a href="https://github.com/liujohnj" target="_blank" onMouseEnter={handleMouseEnterGithub} onMouseLeave={handleMouseLeaveGithub} >
            {!isHoverGithub && <VscGithub size={60} />}
            {isHoverGithub && <VscGithubInverted size={60} />}
          </a>

          <a href="mailto:johnliu@gatech.edu" target="_blank" onMouseEnter={handleMouseEnterEmail} onMouseLeave={handleMouseLeaveEmail} >
            {!isHoverEmail && <AiOutlineMail size={60} />}
            {isHoverEmail && <AiFillMail size={60} />}
          </a>

          <a href="https://discordapp.com/users/753981950305370172" target="_blank" onMouseEnter={handleMouseEnterDiscord} onMouseLeave={handleMouseLeaveDiscord} >
            {!isHoverDiscord && <RiDiscordLine size={60} />}
            {isHoverDiscord && <RiDiscordFill size={60} />}
          </a>

        </div>
        <p className="font-nunito-sans text-md text-zinc-600 self-end mb-4">© 2022 John Liu</p>
      </div>
    </div>
  )
}

export default Connect;