import React from 'react';
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { FaArrowAltCircleDown } from "react-icons/fa";
import AWSCloudPractBadge from "../assets/aws-certified-cloud-practitioner.png";


const Home = () => {

  return (
    <div id="home" className="w-full h-full lg:h-screen pt-20 bg-white">
      <div className="w-full h-full bg-hero bg-no-repeat top-64
                      bg-sz-auto-sm
                      md-short:bg-sz-auto-sm
                      md-tall:bg-sz-auto-sm
                      lg-short:bg-sz-auto-md
                      lg-tall:bg-sz-auto-lg
                      xl-short:bg-sz-auto-xl
                      xl-tall:bg-sz-auto-xl
                      2xl-short:bg-sz-auto-xl
                      2xl-tall:bg-sz-auto-xl

                      bg-pos-base
                      xshort:bg-contain
                      xshort:bg-right
                      sm-short:bg-pos-sm-short
                      sm-med:bg-pos-sm-med
                      sm-tall:bg-pos-sm-tall
                      md-short:bg-pos-md-short
                      md-tall:bg-pos-md-tall
                      lg-short:bg-pos-lg-short
                      lg-tall:bg-pos-lg-tall
                      xl-short:bg-pos-xl-short
                      xl-tall:bg-pos-xl-tall
                      2xl-short:bg-pos-2xl-short
                      2xl-tall:bg-pos-2xl-tall
      ">

        {/* image instead of background for base screens */}
        {/* <div className="w-full h-full flex flex-col items-center"> */}
          {/* <img src={Portrait} alt="hero" className="min-w-[calc(100%+140px)] ml-[-100px]"/> */}
          {/* <img src={Portrait} alt="hero" className="ml-[-screen.height] min-w-max h-full"/> */}
          {/* <img src={Portrait} alt="hero" className="w-1/2"/>
        </div> */}

        {/* left half text */}
        <div className="w-full sm:w-7/12 h-full">

          <div className="w-full h-full flex flex-col pt-72 sm:pt-0 md:justify-center pl-8 pr-4">

            {/* AWS badge */}
            <div className="flex mb-6 sm:mb-16 xl:mb-24 justify-end sm:justify-start">
              <p className="text-xl xl:text-2xl font-nunito-sans text-aws-orange italic tracking-wider mt-auto mb-auto align-middle mr-1 xl:mr-2 2xl:mr-4">New!</p>
              <a href="https://www.credly.com/badges/4018f382-c11f-4fed-af54-f837e6ce3319/public_url" target="_blank">
                <img src={AWSCloudPractBadge } style={{width: '120px'}} alt="AWS badge" />
              </a>
            </div>

            <div className="text-2xl 2xl:text-3xl text-stone-400 font-nunito-sans tracking-[0.5em]">JOHN LIU</div>

            <div className="text-3xl sm:text-4xl md:text-5xl xl:text-6xl font-bold mt-4 mb-4 font-nunito-sans">A Computer Science Master's <span style={{ whiteSpace: 'nowrap' }}> student ...</span></div>

            <div className="text-xl sm:text-2xl md:text-3xl text-stone-500 font-nunito-sans">with communication, teamwork, and other soft skills mission ready on day one.</div>

            <Link className="flex items-center mt-4 cursor-pointer" to="projects">
              <p className="text-xl xl:text-2xl text-black hover:text-steel-blue hover:scale-105">
                View projects
              </p>
              {/* <motion.div
                animate={{ y: [-10, -10, 0, 10, 10] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
              >
                <div className="flex ml-3">
                  <FaArrowAltCircleDown size={22} color={"steelblue"} className="ml-3" />
                </div>
              </motion.div> */}
              <div className="flex h-16 ml-6 content-center">
                <FaArrowAltCircleDown size={22} color={"steelblue"} className="mt-auto mb-auto animate-bounce h-24" />
              </div>
            </Link>

          </div>
        </div>
      </div>
  </div>
  )
}

export default Home;