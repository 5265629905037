import React from "react";


const About = () => {

  return (
    <div name="about" className="flex flex-col pt-32 px-8 bg-white">
      <div className="text-2xl 2xl:text-3xl text-stone-400 font-nunito-sans tracking-[0.5em]">
        <p className="mb-4">ABOUT ME</p>
      </div>

      {/* top level below page break title */}

      <div className="flex flex-col w-full md:w-3/4 lg:w-2/3 mx-auto">
      {/* <div className="flex flex-col md:flex-row items-center md:items-start"> */}
      {/* longwinded */}
      {/* <div className="flex flex-col w-full md:w-2/3"> */}

        {/* O B J E C T I V E */}
        <div className="flex justify-start mt-8">
          <div className="bg-blue-300 min-w-[6px] mr-4"></div>
          <div className="flex flex-col">
            <p className="font-nunito-sans text-2xl font-bold">
              My objective ...
            </p>
            <p className="text-lg text-zinc-800">
            Seeking the right fit where I can do what I love most - develop software that solves real problems. What sets me apart are soft skills like effective written and verbal communication, teamwork and collaboration, managing ambiguity, and adaptability. Applying these with a strong work ethic enables me to be a productive member of an agile team on day one. I am willing to relocate and am eligible for a spring or summer internship.
            </p>
          </div>
        </div>

        {/* E D U C A T I O N */}
        <div className="flex justify-start w-full mt-14">
          <div className="bg-green-300 min-w-[6px] mr-4"></div>
          <div className="flex flex-col w-full">
            <p className="font-nunito-sans text-2xl font-bold">
              Education ...
            </p>

            {/* Grid */}
            <div className="grid grid-cols-[25%_5%_70%] w-full mt-4 text-base">

              {/* Date range */}
              <div className="pr-4">
                <p>Aug. 2021 - present</p>
              </div>

              {/* Circle and vertical line */}
              <div className="flex flex-col items-center">
                <div className="mt-2 w-3 h-3 border border-zinc-500 rounded-full" />
                <div className="w-0 h-full border border-zinc-500" />
              </div>

              {/* School info */}
              <div className="pl-12 -indent-5 text-zinc-800">
                <p><span className="font-semibold">Georgia Institute of Technology (Georgia Tech),</span> Atlanta, GA</p>
                <p><span className="font-semibold">GPA:</span> 4.0/4.0</p>
                <p><span className="font-semibold">M.S. in Computer Science</span> (graduating 2024)</p>
                <p>Relevant courses:</p>
                <div className="ml-4 font-light mb-8">
                  <li>Robotics: AI Techniques</li>
                  <li>Artificial Intelligence</li>
                  <li>Machine Learning for Trading</li>
                  <li>AI, Ethics, and Society</li>
                  <li>Eductional Technology</li>
                  <li>Knowledge-based Artificial Intelligence</li>
                  <li>Software Development Process</li>
                  <li>Natural Language Processing (2024)</li>
                </div>
              </div>

              {/* Date range */}
              <div className="pr-4">
                <p>Aug. 2019 - May 2021</p>
              </div>

              {/* Circle and vertical line */}
              <div className="flex flex-col items-center">
                <div className="w-0 h-2 border border-zinc-500" />
                <div className="w-3 h-3 border border-zinc-500 rounded-full" />
                <div className="w-0 h-full border border-zinc-500" />
              </div>

              {/* School info */}
              <div className="pl-12 text-zinc-800 -indent-5">
                <p><span className="font-semibold">University of Florida,</span> Gainesville, FL</p>
                <p><span className="font-semibold">GPA:</span> 4.0/4.0</p>
                <p><span className="font-semibold">B.S. in Computer Science</span> (awarded May 2021)</p>
                <p>Relevant courses:</p>
                <div className="ml-4 font-light">
                  <li>Data Structures & Algorithms</li>
                  <li>Databases (Oracle SQL)</li>
                  <li>Performant Programming in Python</li>
                  <li>Penetration Testing</li>
                </div>
              </div>

            </div>

          </div>
        </div>

        {/* E X P E R I E N C E */}
        <div className="flex justify-start w-full mt-14">
          <div className="bg-yellow-300 min-w-[6px] mr-4"></div>
          <div className="flex flex-col w-full">
            <p className="font-nunito-sans text-2xl font-bold">
              Experience ...
            </p>

          {/* ORACLE Grid */}
            <div className="grid grid-cols-[25%_5%_70%] w-full mt-4 text-base">

              {/* Date range */}
              <div className="pr-4">
                <p>May 2023 - Aug 2023</p>
              </div>

              {/* Circle and vertical line */}
              <div className="flex flex-col items-center">
                <div className="mt-2 w-3 h-3 border border-zinc-500 rounded-full" />
                <div className="w-0 h-full border border-zinc-500" />
              </div>

              {/* Employment info */}
              <div className="pl-12 -indent-5 text-zinc-800">
                <p className="font-bold">Software Engineer Intern</p>
                <p><span className="font-semibold">Oracle America, Inc.</span> Pleasanton, CA</p>
                <div className="ml-4 font-light">
                  <li>Developed and deployed to pre-production prior to end of internship components for new client-side application using Preact (React), TypeScript, open-source Oracle JavaScript Extension Toolkit (JET) libraries, and REST APIs to migrate existing features of internal automation tool built using NodeJS and MongoDB in back end and Angular in front end to perform diagnostic SQL queries.</li>
                  <li>Built new features for above application that result in productivity savings of one hour per request by SaaS DevOps team members performing batch tasks to monitor critical metrics of production machines on various Oracle Cloud infrastructures.</li>
                  <li>As Pleasanton office hub representative, coordinated on-site activities for 12 interns.</li>
                </div>
              </div>

            </div>

            {/* Georgia Tech Grid */}
            <div className="grid grid-cols-[25%_5%_70%] w-full mt-4 text-base">

              {/* Date range */}
              <div className="pr-4">
                <p>May 2022 - present</p>
              </div>

              {/* Circle and vertical line */}
              <div className="flex flex-col items-center">
                <div className="mt-2 w-3 h-3 border border-zinc-500 rounded-full" />
                <div className="w-0 h-full border border-zinc-500" />
              </div>

              {/* Employment info */}
              <div className="pl-12 -indent-5 text-zinc-800">
                <p className="font-bold">Graduate Teaching Assistant</p>
                <p><span className="font-semibold">Georgia Institute of Technology (Georgia Tech),</span> Atlanta, GA</p>
                <div className="ml-4 font-light">
                  <li>Responsible for ~500 students in graduate-level Artificial Intelligence for Robotics course that centers on AI technologies and topics for autonomous vehicles, including localization, Kalman filters, particle filters, search, and A* algorithms.</li>
                  <li>Supporting students to achieve learning outcomes by participating in hosted office hours, responding to students’ queries on electronic discussion boards, and performing code reviews and debugging students’ code for projects and assignments.</li>
                  <li>Creating and updating lesson-based content and student exams for course’s learning management system (Canvas).</li>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* V O L U N T E E R */}
        <div className="flex justify-start w-full mt-14">
          <div className="bg-purple-300 min-w-[6px] mr-4"></div>
          <div className="flex flex-col w-full">
            <p className="font-nunito-sans text-2xl font-bold">
              Volunteer & Memberships ...
            </p>

            {/* Grid */}
            <div className="grid grid-cols-[25%_5%_70%] w-full mt-4 text-base">

              {/* Date range */}
              <div className="pr-4">
                <p>Sep. 2022 - present</p>
              </div>

              {/* Empty column */}
              <div />

              {/* Volunteer info */}
              <div className="pl-12 -indent-5 text-zinc-800">
                <p className="font-bold">Operations Lead</p>
                <p className="font-semibold">Google Developer Student Club @ Georgia Tech</p>
                <div className="ml-4 mb-8 font-light">
                  <li>Managing logistical tasks related to club-hosted workshops and events.</li>
                  <li>Led programming team for full stack project web application (using React) solving local not-for-profit’s technology needs.</li>
                  <li>Spearheading club obtaining Registered Student Organization (RSO) status, which will result in increasing funding by more than ten-fold.</li>
                </div>
              </div>

              {/* Date range */}
              <div className="pr-4">
                <p>June 2021 - present</p>
              </div>

              {/* Empty column */}
              <div />

              {/* Volunteer info */}
              <div className="pl-12 text-zinc-800 -indent-5">
                <p className="font-bold">Member</p>
                <p className="font-semibold">Society of Hispanic Professional Engineers (SHPE)</p>
                <div className="ml-4 mb-8 font-light">
                  <li>Participated at 2022 SHPE National Convention (Charlotte, NC).</li>
                  <li>Participated at 2023 SHPE National Convention (Salt Lake City, UT).</li>
                </div>
              </div>

              {/* Date range */}
              <div className="pr-4">
                <p>Aug 2019 - Aug 2021</p>
              </div>

              {/* Empty column */}
              <div />

              {/* Volunteer info */}
              <div className="pl-12 text-zinc-800 -indent-5">
                <p className="font-bold">Co-chair, Parking Fundraising Committee</p>
                <p className="font-semibold">Pinellas County Center for the Arts</p>
                <div className="ml-4 font-light">
                  <li>Co-chaired committee that raised not-for-profit funds directing attendee parking at large local festivals.</li>
                  <li>Responsible for all planning, logistics, and volunteer staffing related matters.</li>
                  <li>At annual premier arts and crafts festivals for 2019 and 2020, raised ~$6,000 each year, representing the organization's largest fundraising efforts those two years, by directing the parking of more than 3,000 cars over each two-day weekend, with 45+ volunteeers at each event directly reporting to me. </li>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default About;